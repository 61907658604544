<template>
  <!-- Topbar -->
  <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
<!--        unmark後注意 在login 畫面會不會出現-->

<!--        &lt;!&ndash; Sidebar Toggle (Topbar) &ndash;&gt;-->
<!--        <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">-->
<!--          <i class="fa fa-cog"></i>-->
<!--        </button>-->

<!--        &lt;!&ndash; Topbar Search &ndash;&gt;-->
<!--        <form class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">-->
<!--          <div class="input-group">-->
<!--            <input type="text" class="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2">-->
<!--            <div class="input-group-append">-->
<!--              <button class="btn btn-primary" type="button">-->
<!--                <i class="fas fa-search fa-sm"></i>-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </form>-->
    <div class="c-header-nav">
      目前登入者:{{headerBarInfo}}
    </div>
<!--        &lt;!&ndash; Topbar Navbar &ndash;&gt;-->
<!--        <ul class="navbar-nav ml-auto">-->

<!--          &lt;!&ndash; Nav Item - Search Dropdown (Visible Only XS) &ndash;&gt;-->
<!--          <li class="nav-item dropdown no-arrow d-sm-none">-->
<!--            <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
<!--              <i class="fas fa-search fa-fw"></i>-->
<!--            </a>-->
<!--            &lt;!&ndash; Dropdown - Messages &ndash;&gt;-->
<!--            <div class="dropdown-menu dropdown-menu-right p-3 shadow animated&#45;&#45;grow-in" aria-labelledby="searchDropdown">-->
<!--              <form class="form-inline mr-auto w-100 navbar-search">-->
<!--                <div class="input-group">-->
<!--                  <input type="text" class="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2">-->
<!--                  <div class="input-group-append">-->
<!--                    <button class="btn btn-primary" type="button">-->
<!--                      <i class="fas fa-search fa-sm"></i>-->
<!--                    </button>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </form>-->
<!--            </div>-->
<!--          </li>-->

<!--          &lt;!&ndash; Nav Item - Alerts &ndash;&gt;-->
<!--          <li class="nav-item dropdown no-arrow mx-1">-->
<!--            <a class="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
<!--              <i class="fas fa-bell fa-fw"></i>-->
<!--              &lt;!&ndash; Counter - Alerts &ndash;&gt;-->
<!--              <span class="badge badge-danger badge-counter">3+</span>-->
<!--            </a>-->
<!--            &lt;!&ndash; Dropdown - Alerts &ndash;&gt;-->
<!--            <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated&#45;&#45;grow-in" aria-labelledby="alertsDropdown">-->
<!--              <h6 class="dropdown-header">-->
<!--                Alerts Center-->
<!--              </h6>-->
<!--              <a class="dropdown-item d-flex align-items-center" href="#">-->
<!--                <div class="mr-3">-->
<!--                  <div class="icon-circle bg-primary">-->
<!--                    <i class="fas fa-file-alt text-white"></i>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <div class="small text-gray-500">December 12, 2019</div>-->
<!--                  <span class="font-weight-bold">A new monthly report is ready to download!</span>-->
<!--                </div>-->
<!--              </a>-->
<!--              <a class="dropdown-item d-flex align-items-center" href="#">-->
<!--                <div class="mr-3">-->
<!--                  <div class="icon-circle bg-success">-->
<!--                    <i class="fas fa-donate text-white"></i>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <div class="small text-gray-500">December 7, 2019</div>-->
<!--                  $290.29 has been deposited into your account!-->
<!--                </div>-->
<!--              </a>-->
<!--              <a class="dropdown-item d-flex align-items-center" href="#">-->
<!--                <div class="mr-3">-->
<!--                  <div class="icon-circle bg-warning">-->
<!--                    <i class="fas fa-exclamation-triangle text-white"></i>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <div class="small text-gray-500">December 2, 2019</div>-->
<!--                  Spending Alert: We've noticed unusually high spending for your account.-->
<!--                </div>-->
<!--              </a>-->
<!--              <a class="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>-->
<!--            </div>-->
<!--          </li>-->

<!--&lt;!&ndash;          &lt;!&ndash; Nav Item - Messages &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;          <li class="nav-item dropdown no-arrow mx-1">&ndash;&gt;-->
<!--&lt;!&ndash;            <a class="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">&ndash;&gt;-->
<!--&lt;!&ndash;              <i class="fas fa-envelope fa-fw"></i>&ndash;&gt;-->
<!--&lt;!&ndash;              &lt;!&ndash; Counter - Messages &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;              <span class="badge badge-danger badge-counter">7</span>&ndash;&gt;-->
<!--&lt;!&ndash;            </a>&ndash;&gt;-->
<!--&lt;!&ndash;            &lt;!&ndash; Dropdown - Messages &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated&#45;&#45;grow-in" aria-labelledby="messagesDropdown">&ndash;&gt;-->
<!--&lt;!&ndash;              <h6 class="dropdown-header">&ndash;&gt;-->
<!--&lt;!&ndash;                Message Center&ndash;&gt;-->
<!--&lt;!&ndash;              </h6>&ndash;&gt;-->
<!--&lt;!&ndash;              <a class="dropdown-item d-flex align-items-center" href="#">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="dropdown-list-image mr-3">&ndash;&gt;-->
<!--&lt;!&ndash;                  <img class="rounded-circle" src="https://source.unsplash.com/fn_BT9fwg_E/60x60" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="status-indicator bg-success"></div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="font-weight-bold">&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="text-truncate">Hi there! I am wondering if you can help me with a problem I've been having.</div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="small text-gray-500">Emily Fowler · 58m</div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;              </a>&ndash;&gt;-->
<!--&lt;!&ndash;              <a class="dropdown-item d-flex align-items-center" href="#">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="dropdown-list-image mr-3">&ndash;&gt;-->
<!--&lt;!&ndash;                  <img class="rounded-circle" src="https://source.unsplash.com/AU4VPcFN4LE/60x60" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="status-indicator"></div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="text-truncate">I have the photos that you ordered last month, how would you like them sent to you?</div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="small text-gray-500">Jae Chun · 1d</div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;              </a>&ndash;&gt;-->
<!--&lt;!&ndash;              <a class="dropdown-item d-flex align-items-center" href="#">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="dropdown-list-image mr-3">&ndash;&gt;-->
<!--&lt;!&ndash;                  <img class="rounded-circle" src="https://source.unsplash.com/CS2uCrpNzJY/60x60" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="status-indicator bg-warning"></div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="text-truncate">Last month's report looks great, I am very happy with the progress so far, keep up the good work!</div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="small text-gray-500">Morgan Alvarez · 2d</div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;              </a>&ndash;&gt;-->
<!--&lt;!&ndash;              <a class="dropdown-item d-flex align-items-center" href="#">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="dropdown-list-image mr-3">&ndash;&gt;-->
<!--&lt;!&ndash;                  <img class="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="status-indicator bg-success"></div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="text-truncate">Am I a good boy? The reason I ask is because someone told me that people say this to all dogs, even if they aren't good...</div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="small text-gray-500">Chicken the Dog · 2w</div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;              </a>&ndash;&gt;-->
<!--&lt;!&ndash;              <a class="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </li>&ndash;&gt;-->

<!--&lt;!&ndash;          <div class="topbar-divider d-none d-sm-block"></div>&ndash;&gt;-->

<!--&lt;!&ndash;          &lt;!&ndash; Nav Item - User Information &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;          <li class="nav-item dropdown no-arrow">&ndash;&gt;-->
<!--&lt;!&ndash;            <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">&ndash;&gt;-->
<!--&lt;!&ndash;              <span class="mr-2 d-none d-lg-inline text-gray-600 small">Valerie Luna</span>&ndash;&gt;-->
<!--&lt;!&ndash;              <img class="img-profile rounded-circle" src="https://source.unsplash.com/QAB-WJcbgJk/60x60">&ndash;&gt;-->
<!--&lt;!&ndash;            </a>&ndash;&gt;-->
<!--&lt;!&ndash;            &lt;!&ndash; Dropdown - User Information &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="dropdown-menu dropdown-menu-right shadow animated&#45;&#45;grow-in" aria-labelledby="userDropdown">&ndash;&gt;-->
<!--&lt;!&ndash;              <a class="dropdown-item" href="#">&ndash;&gt;-->
<!--&lt;!&ndash;                <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                Profile&ndash;&gt;-->
<!--&lt;!&ndash;              </a>&ndash;&gt;-->
<!--&lt;!&ndash;              <a class="dropdown-item" href="#">&ndash;&gt;-->
<!--&lt;!&ndash;                <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                Settings&ndash;&gt;-->
<!--&lt;!&ndash;              </a>&ndash;&gt;-->
<!--&lt;!&ndash;              <a class="dropdown-item" href="#">&ndash;&gt;-->
<!--&lt;!&ndash;                <i class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                Activity Log&ndash;&gt;-->
<!--&lt;!&ndash;              </a>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="dropdown-divider"></div>&ndash;&gt;-->
<!--&lt;!&ndash;              <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">&ndash;&gt;-->
<!--&lt;!&ndash;                <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                Logout&ndash;&gt;-->
<!--&lt;!&ndash;              </a>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </li>&ndash;&gt;-->

<!--        </ul>-->

  </nav>
  <!-- End of Topbar -->
</template>

<script>
import session from "../../../common/session";
import * as ubossCommon from "@/pages/commonUBoss/ubossCommon";
// import $ from "jquery"
// import "bootstrap"
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'COM_Header',
  components: {},
  data: function() {
    return {
      showDialogLogin: false,
      headerBarInfo:"",
    };
  },
  methods: {
    test:function(){
    },
    showLogin: function () {
      this.showDialogLogin = true;
    },
    isLogined: function() {
      return session.isLogined();
    },
    login_info_accUid: function() {
      return session.login_info_accUid();
    },

    // dlgLogin:function(){
    //   this.$bvModel.show('modal_user_login')
    // }
  },
  created() {
    this.headerBarInfo=session.getLoginId(ubossCommon.SessionKeyPrefix_Bk)
  }

  // components: {
  //   HelloWorld
  // }
}
</script>
