
<template>
  <div>


    <h1>JWT Test</h1>
    <button class="btn btn-primary" @click="LoginAdmin" >Login Admin</button>
    <button class="btn btn-primary" @click="LoginShop" >Login Shop</button>
    <button class="btn btn-primary" @click="AuthTest_Get1" >AuthTest_Get1 No Authorize</button>
    <button class="btn btn-primary" @click="AuthTest_Get2" >AuthTest_Get2 Authorize</button>
    <button class="btn btn-primary" @click="AuthTest_Get3" >AuthTest_Get3 Authorize Admin</button>
    <button class="btn btn-primary" @click="AuthTest_Get4" >AuthTest_Get4 Authorize Shop</button> |
    <button class="btn btn-primary" @click="AuthTest_Get1_Admin" >AuthTest_Get1 No Authorize (Admin)</button>

    {{returnMsg}}
  </div>
</template>

<script>
import apiUBossAdmin from "@/pages/ubossAdmin/util/apiUBossAdmin";
import apiUBossShop from "@/pages/ubossShop/util/apiUBossShop";
import session from "@/pages/common/session";

export default {
  name: "Test",
  components:{
    // 'cleave':Cleave
  },
  data: function() {
    return {
      returnMsg:"",
    }
  },
  methods: {
    async LoginAdmin(){
      let returnMsg=await apiUBossShop.api_AuthTest_Login_Admin("taylorA","0915071457")

      session.setToken(returnMsg)
      console.log(returnMsg);
    },
    async LoginShop(){
      let returnMsg=await apiUBossShop.api_AuthTest_Login_Shop("taylorS","0915071457")

      session.setToken(returnMsg)
      console.log(returnMsg);
    },
    async AuthTest_Get1(){
      let returnMsg=await apiUBossShop.api_AuthTest_Get1();

      console.log(returnMsg);
    },
    async AuthTest_Get2(){
      let returnMsg=await apiUBossShop.api_AuthTest_Get2();

      console.log(returnMsg);
    },
    async AuthTest_Get3(){
      let returnMsg=await apiUBossShop.api_AuthTest_Get3();

      console.log(returnMsg);
    },
    async AuthTest_Get4(){
      let returnMsg=await apiUBossShop.api_AuthTest_Get4();

      console.log(returnMsg);
    },

    async AuthTest_Get1_Admin(){
      let returnMsg=await apiUBossAdmin.api_AuthTest_Get1();

      console.log(returnMsg);
    },
    async btnTest01(){
      // let var1=process.env.BASE_URL;
      // let var2=window.location.host.split(".")
    }
  },
  mounted() {


    //this.loadData()
  },
}
</script>

<style scoped>

</style>
