<template>
  <!-- Sidebar -->
  <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar" v-if="showMenu">

    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
      <div class="sidebar-brand-icon rotate-n-15">
        <!--        <i class="fas fa-laugh-wink"></i>-->
      </div>
      <div class="sidebar-brand-text mx-3">
        UBoss Adm
      </div>
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0">

    <!-- Nav Item - Dashboard -->
    <li class="nav-item active">
      <a class="nav-link" href="index.html">
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span>hi:{{userId}}</span></a>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider">

    <!-- Heading -->
    <div class="sidebar-heading">
      功能
    </div>

    <!-- Nav Item - Pages Collapse Menu -->
    <!--    <li class="nav-item">-->
    <!--      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">-->
    <!--        <i class="fas fa-fw fa-cog"></i>-->
    <!--        <span>系統管理</span>-->
    <!--      </a>-->
    <!--      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">-->
    <!--        <div class="bg-white py-2 collapse-inner rounded">-->
    <!--          <h6 class="collapse-header"></h6>-->
    <!--          <a class="collapse-item" href="/CodeListCourseCatalogList">課程Category</a>-->
    <!--          <a class="collapse-item" href="/CodeListCourseTargetList">課程目標受眾</a>-->
    <!--          <a class="collapse-item" href="/SupplierGroupList">供應商群組</a>-->
    <!--          <a class="collapse-item" href="/CodeListSupplierAnnouncementCatalogList">Supplier announcement 分類</a>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </li>-->

    <!--    &lt;!&ndash; Nav Item - Utilities Collapse Menu &ndash;&gt;-->
    <!--    <li class="nav-item">-->
    <!--      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="true" aria-controls="collapseUtilities">-->
    <!--        <i class="fas fa-fw fa-wrench"></i>-->
    <!--        <span>課程管理</span>-->
    <!--      </a>-->
    <!--      <div id="collapseUtilities" class="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">-->
    <!--        <div class="bg-white py-2 collapse-inner rounded">-->
    <!--          <h6 class="collapse-header"></h6>-->
    <!--          <a class="collapse-item" href="/CourseList">課程管理</a>-->
    <!--          <a class="collapse-item" href="/CourseMissionAllocList">課程分派管理</a>-->
    <!--          <a class="collapse-item" href="/CourseMissionAllocReport">課程完成率</a>-->
    <!--&lt;!&ndash;          <a class="collapse-item" href="/CourseReport2">Tier-1 課程完成率 (開發中)</a>&ndash;&gt;-->
    <!--&lt;!&ndash;          <a class="collapse-item" href="/CourseReport3">Tier-1 員工課程完成率 (開發中)</a>&ndash;&gt;-->
    <!--&lt;!&ndash;          <a class="collapse-item" href="/CourseReport4">Tier-2 課程完成率 (開發中)</a>&ndash;&gt;-->
    <!--&lt;!&ndash;          <a class="collapse-item" href="/CourseReport5">Guest 課程完成率 (開發中)</a>&ndash;&gt;-->
    <!--&lt;!&ndash;          <a class="collapse-item" href="/CourseQuizeList">課程試題管理 (開發中)</a>&ndash;&gt;-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </li>-->

    <!-- Nav Item - Charts -->
    <li class="nav-item" >
      <a class="nav-link" @click="gotoHome">
        <i class="fas fa-fw fa-chart-area"></i>
        <span>首頁</span>
      </a>
    </li>

<!--    <li class="nav-item" >-->
<!--      <a class="nav-link" href="#">-->
<!--        <i class="fas fa-fw fa-chart-area"></i>-->
<!--        <span>客戶資料</span>-->
<!--      </a>-->
<!--    </li>-->



    <!-- Nav Item - Charts -->
    <li class="nav-item">
      <a class="nav-link" @click="logout">
        <i class="fas fa-fw fa-chart-area"></i>
        <span>登出</span></a>
    </li>







    <!--    &lt;!&ndash; Divider &ndash;&gt;-->
    <!--    <hr class="sidebar-divider">-->


    <!--    &lt;!&ndash; Nav Item - Charts &ndash;&gt;-->
    <!--    <li class="nav-item">-->
    <!--      <a class="nav-link" href="/Test">-->
    <!--        <i class="fas fa-fw fa-chart-area"></i>-->
    <!--        <span>Test</span></a>-->
    <!--    </li>-->

    <!--    &lt;!&ndash; Nav Item - Charts &ndash;&gt;-->
    <!--    <li class="nav-item">-->
    <!--      <a class="nav-link" href="/TestGrid">-->
    <!--        <i class="fas fa-fw fa-chart-area"></i>-->
    <!--        <span>TestGrid</span></a>-->
    <!--    </li>-->


    <!-- Sidebar Toggler (Sidebar) -->
    <!--    <div class="text-center d-none d-md-inline">-->
    <!--      <button class="rounded-circle border-0" id="sidebarToggle"></button>-->
    <!--    </div>-->

  </ul>
  <!-- End of Sidebar -->
</template>

<script>
import session from "../../../common/session";

export default {
  name: "COM_Menu",
  data: function() {
    return {
      showMenu: true,
      userId:"",
      showFunReport:false,
    };
  },
  methods: {
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    logout(){
      session.logout(this.$router,this)
    }
  },
  mounted() {


    //this.loadData()

    this.userId=session.getAccUid()
    //
    // if (this.userId==="ADMIN"){
    //   this.showFunReport=true;
    // }
  },
}
</script>

<style scoped>
/*.navbar-nav{*/
/*  width: 200px !important;*/
/*}*/
/*.sidebar .nav-item .nav-link{*/
/*  width: 100%;*/
/*}*/
</style>
