import * as dec  from "@/pages/common/declare";



export default {
    uuid:function() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    },
    uuidForJavaScript:function() {
        return this.uuid().replaceAll("-","");
    },
    handle_tempid:function(id) {
        if (isNaN(id) || Number(id)===0){
            return this.uuid();
        }else{
            return "";
        }
    },
    Fmt_JsonDateStr(dateStr){
        if (dateStr){
            return new Date(dateStr).toISOString().replace("T"," ").replace('Z','').replace(":00.000","")
        }else{
            return ""
        }
    },
    Fmt_JsonDateStr2(dateObj){
        if (dateObj instanceof Date){
            //return dateStr.toISOString().substring(0,16).replace("T"," ")
            let dateStr =
                dateObj.getFullYear()+ "-" +
                ("00" + (dateObj.getMonth() + 1)).slice(-2) + "-" +
                ("00" + dateObj.getDate()).slice(-2)
                 + " " +
                ("00" + dateObj.getHours()).slice(-2) + ":" +
                ("00" + dateObj.getMinutes()).slice(-2) + ":" +
                ("00" + dateObj.getSeconds()).slice(-2);

            return dateStr
        }else{
            return dateObj
        }
    },
    FmtMoney3D(value){
        return this.FmtMoneyDigital(value,3)
    },
    FmtMoney2D(value){
        return this.FmtMoneyDigital(value,2)
    },
    FmtMoney0(value){
        return this.formatMoney(value,0);
    },

    formatMoney(number, decPlaces) {
        // console.log(number +"  "+decPlaces)
        if (!number){
            return "0";
        }
        if (Number(number)===0){
            return "0";
        }

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: decPlaces,
            maximumFractionDigits: decPlaces,
        })

        var returnValue= formatter.format(number) // "$1,000.00"

        // console.log(returnValue)

        return returnValue
    },
    // formatMoney(number, decPlaces, decSep, thouSep) {
    //     console.log(number)
    //
    //
    //     decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
    //         decSep = typeof decSep === "undefined" ? "." : decSep;
    //     thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    //     var sign = number < 0 ? "-" : "";
    //     var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
    //     var j = (j = i.length) > 3 ? j % 3 : 0;
    //
    //     var returnValue= sign +
    //         (j ? i.substr(0, j) + thouSep : "") +
    //         i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
    //         (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
    //
    //     console.log(returnValue)
    //     return returnValue;
    // },
    FmtMoneyDigital(value,digital){
        if (!value){
            return "0";
        }
        if (Number(value)===0){
            return "0";
        }
        return this.formatMoney(value,digital);
    },
    FmtTrimTimeString(value){
      return value.replace("T00:00:00","");
    },
    GetValueNumber(value){
        if (this.isNumeric(value)) {
            return Number(value)
        }else{
            return 0;
        }
    },
    GetValueNumberOrDefault(value,defaultValue){
        if (this.isNumeric(value)) {
            return Number(value)
        }else{
            return defaultValue;
        }
    },
    isNumeric(num){
        return !isNaN(num)
    },
    ShowLoading(Vue){
        let loader = Vue.$loading.show();
        return loader
    },
    HideLoading(loader){
        loader.hide()
    },
    SplitWithCommaThenToNumber(tokenStr){
        return this.SplitWithComma(tokenStr).map(s=>Number(s))
    },
    SplitWithComma(tokenStr){
        if (tokenStr){
            return tokenStr.split(",");
        }else{
            return [];
        }
    },
    ShowMessage(message,title){
        if (title===undefined){
            title="Message"
        }

        Swal.fire({            // eslint-disable-line
            // title: message,
            html:message,
            showCloseButton: true,
            button: "OK!",
            closeButtonHtml: '<i class="be-icon be-icon-close" aria-hidden="true"></i>',
            // showCancelButton: true,
            // cancelButtonText: 'Cancel',
            // confirmButtonText: 'Delete',
            focusConfirm: false,
            reverseButtons: false,
            allowOutsideClick: true,
            showClass: {
                popup: 'animate__animated animate__fadeIn'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOut'
            },
            customClass: {
                container: 'custom-sweetAlert',
            }
        });
    },
    ShowConfirm(){
        // str
        Swal.fire({            // eslint-disable-line
            title: 'Are you sure?',
            showCloseButton: true,
            closeButtonHtml: '<i class="be-icon be-icon-close" aria-hidden="true"></i>',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete',
            focusConfirm: false,
            reverseButtons: true,
            allowOutsideClick: false,
            showClass: {
                popup: 'animate__animated animate__fadeIn'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOut'
            },
            customClass: {
                container: 'custom-sweetAlert',
                // title: 'full-content', Use When has title and text in the same time
            }
        });
    },
    handle_api_alertSuccess(dataReturn){
        if (dataReturn.apiResultCode===dec.API_OK){
            this.showErrorMessage('success')
            return ""
        }else{
            let message=this.parseErrorMessage(dataReturn);
            this.showErrorMessage(message);
            throw new Error(message);
        }
    },
    handle_api_throwError(dataReturn){
        if (dataReturn.apiResultCode===dec.API_OK){
            return ""
        }else{
            let message=this.parseErrorMessage(dataReturn);
            this.showErrorMessage(message);
            throw new Error(message);
        }
    },

    //test ok
    handle_api_returnData(dataReturn){
        if (dataReturn.apiResultCode===dec.API_OK ){
            return JSON.parse(dataReturn.apiResult);
        }else{
            let message=this.parseErrorMessage(dataReturn);
            this.showErrorMessage(message);
            throw new Error(message);//QQ
        }
    },
    handle_api_parseData(dataReturn){
        return JSON.parse(dataReturn.apiResult);
    },

    showErrorMessage(message){
        if (message){
            this.ShowMessage(message);
        }
    },

    parseErrorMessage(dataReturn){
        let message="";

        try{
            let errorObj= JSON.parse(dataReturn.apiResult);

            message=errorObj.error.errorMessage;
        }catch (exception){
            message=dataReturn.apiResult;
        }

        return message;
    },
    //---------------------------------------
    trimLastSlash(str){
        return str.endsWith('/') ?
            str.slice(0, -1) :
            str;
    },
    trimFirstSlash(str){
        return str.replace(/^\/|\/$/g, '');
    },

    getFileName(url){
        let leafname= url.split('\\').pop().split('/').pop();
        return leafname;
    },
    toDownloadLink(rootUrl, relativePath){
        return this.trimLastSlash(rootUrl) + "/"+ this.trimFirstSlash(relativePath);
    },
    downloadFile(url){
        window.open(url,"_blank")
    },
    toInt(value){
        let valueNew=value || 0

        return parseInt(valueNew)
    },
    toIntStr(value){
        let valueNew=value || 0

        return parseInt(valueNew) +"";
    },

    //-----------------------------
    UI_countDown(seconds,vueTextboxRef){
        var timeleft = 10;
        var downloadTimer = setInterval(function(){
            if(timeleft <= 0){
                clearInterval(downloadTimer);
                vueTextboxRef.innerHTML = "";
            } else {
                vueTextboxRef.innerHTML = timeleft + "";
            }
            timeleft -= 1;
        }, 1000);
    },
    ConvertCodeListToObject_textvalue(codeListObject){
        let aResult=[];
        Object.entries(codeListObject).forEach(([key, value]) => {
            let option={
                text:key,
                value:value,
            }

            aResult.push(option);
        });

        return aResult;
    },
    ConvertCodeListToObject_keyvalue(codeListObject){
        let aResult=[];
        Object.entries(codeListObject).forEach(([key, value]) => {
            let option={
                key:key,
                value:value,
            }

            aResult.push(option);
        });

        return aResult;
    },

    GetDailyId(){
        let day=localStorage.getItem('dailyDay') +"";
        let id=localStorage.getItem('dailyId') +"";

        let today=new Date().toISOString().substring(0, 10);//yyyy-mm-dd
        if (id && day && day===today){
            localStorage.setItem('dailyId',Number(id)+1);
            return Number(id)+1;
        }else{
            localStorage.setItem('dailyDay',today);
            localStorage.setItem('dailyId',1);
            return 1;
        }
    },
    GetRandom(min,max){
        return Math.floor(Math.random()*(max-min+1))+min;
    },
    GetStringFromLocalStorage(key){
        let value=localStorage.getItem(key);
        return value;
    },
    SetStringToLocalStorage(key,value){
        localStorage.setItem(key, value);
    },
    GetObjectFromLocalStorage(key){
        let value=localStorage.getItem(key);
        return JSON.parse(value);
    },
    SetObjectToLocalStorage(key,value){
        localStorage.setItem(key, JSON.stringify(value));
    },
    ObjectOverride(objDefault, objNew){
        for(let key1 in objNew) {
            for(let key2 in objNew[key1]) {
                if(objNew[key1][key2] !== undefined && objNew[key1][key2] !== null){
                    objDefault[key1][key2] = objNew[key1][key2];
                }
            }
        }
    }

    // handle_tempid:function(id,formDataHavaTempId) {
    //     if (isNaN(id) || Number(id)===0){
    //         if (!formDataHavaTempId.tempid){
    //             formDataHavaTempId.tempid=this.uuid();
    //         }
    //     }else{
    //         return "";
    //     }
    // },
}
