

export default {
    // called by Vue.use(FirstPlugin)
    install(Vue, options) {// eslint-disable-line
        // Vue.prototype.gotoVue = (vue) => {
        //     vue.$router.push({ name: 'ProductDetail', query: { SystemId: ""}})
        // }

        // create a mixin
        Vue.mixin({
            methods:{
                goto(name){
                    this.$router.push({ name: name})
                },
                gotoBack(){
                    this.$router.back();
                },
                gotoWithSystemId(name,systemId){
                    this.$router.push({ name: name, query: { systemId: systemId}})
                },
                gotoWithQuery(name,queryObject){
                    this.$router.push({ name: name, query: queryObject})
                },
                gotoHome(){
                    this.$router.push({ name: 'Index'})
                },
            }
        });

        // }

    }
}
