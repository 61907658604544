var apiUrl=process.env.VUE_APP_apiUrl;
var apiPathBase="api/UBossAdminApi/";
import AxiosManager from "@/pages/common/axiosLib/axiosManager";
import * as ubossCommon from "@/pages/commonUBoss/ubossCommon";
import ubossCrudApiManager from "@/pages/commonUBoss/ubossCrudApiManager";

export default {
    //---------------axios------------------------
    getAxiosManager(){
        return new AxiosManager(ubossCommon.SessionKeyPrefix_Adm + ubossCommon.TokenKeyLastFix)
    },
    //---------------------------------------
    getApiManager_General() {
        return new ubossCrudApiManager(apiUrl,apiPathBase,"", this.getAxiosManager(),null)
    },
    //---------------user------------------------

    async api_SendConfirmCode ( sClientKey, sTel)
    {
        var sendData={
            sClientKey:sClientKey,
            sTel:sTel,
        };
        return this.getApiManager_General().api_CallMethod_ByParamObj("SendConfirmCode",sendData);
    },

    async api_UserReg_Check_ConfirmCode ( sClientKey, sConfirmCode)
    {
        var sendData={
            sClientKey:sClientKey,
            sConfirmCode:sConfirmCode,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("UserReg_Check_ConfirmCode",sendData);
    },

    async api_UserReg_Check_LoginId ( sLoginId)
    {
        var sendData={
            sLoginId:sLoginId,
        };
        return this.getApiManager_General().api_CallMethod_ByParamObj("UserReg_Check_LoginId",sendData);
    },

    async api_User_Reg ( memberRecordWebInput)
    {
        return this.getApiManager_General().api_CallMethod_SendObj("User_Reg",memberRecordWebInput);
    },

    async api_User_Login ( sLoginId, sPwd)
    {
        var sendData={
            sLoginId:sLoginId,
            sPwd:sPwd,
        };
        return this.getApiManager_General().api_CallMethod_ByParamObj("User_Login",sendData);
    },

    async api_User_ResetPwd (sLoginId )
    {
        var sendData={
            sLoginId:sLoginId
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("User_ResetPwd",sendData);
    },

    async api_User_ChangePwd ( sCurrentPwd, sNewPwd)
    {
        var sendData={
            sCurrentPwd:sCurrentPwd,
            sNewPwd:sNewPwd,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("User_ChangePwd",sendData);
    },

    async api_UserBasicInfoGet ()
    {
        var sendData={
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("UserBasicInfoGet",sendData);
    },

    async api_UserBasicInfoSave ( UserRecordBasicInfo)
    {
        return this.getApiManager_General().api_CallMethod_SendObj("UserBasicInfoSave",UserRecordBasicInfo);
    },

    //---------------------------------------
    async api_AuthTest_Get1 ()
    {
        var sendData={
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("AuthTest_Get1",sendData);
    },
}