<template>
<div>

</div>
</template>

<script>
// import HelloWorld from '@/components/HelloWorld.vue'
//
export default {
  name: 'COM_Footer',
  // components: {
  //   HelloWorld
  // }
}
</script>
