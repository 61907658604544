var apiUrl=process.env.VUE_APP_apiUrl;
var apiPathBase="api/UBossShopApi/";
import AxiosManager from "@/pages/common/axiosLib/axiosManager";
import * as ubossCommon from "@/pages/commonUBoss/ubossCommon";
import ubossCrudApiManager from "@/pages/commonUBoss/ubossCrudApiManager";

export default {
    //---------------axios------------------------
    getAxiosManager(){
        return new AxiosManager(ubossCommon.SessionKeyPrefix_Shop + ubossCommon.TokenKeyLastFix)
    },
    //---------------------------------------
    getApiManager_General() {
        return new ubossCrudApiManager(apiUrl,apiPathBase,"", this.getAxiosManager(),null)
    },
    //---------------------------------------
    async api_Product_ClientQuery ( queryObject)
    {
        return this.getApiManager_General().api_CallMethod_SendObj("Product_ClientQuery",queryObject);
    },
    async api_Product_ClientQueryByTag ( tag)
    {
        return this.getApiManager_General().api_CallMethod_SendObj("Product_ClientQueryByTag",tag);
    },

    async api_Product_ClientGet ( systemId)
    {
        var sendData={
            systemId:systemId,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("Product_ClientGet",sendData);
    },

    async api_AuthTest_Login_Admin ( sUserName,sUserId)
    {
        var sendData={
            sUserName:sUserName,
            sUserId:sUserId,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("AuthTest_Login_Admin",sendData);
    },

    async api_AuthTest_Login_Shop ( sUserName,sUserId)
    {
        var sendData={
            sUserName:sUserName,
            sUserId:sUserId,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("AuthTest_Login_Shop",sendData);
    },

    async api_AuthTest_Get1 ()
    {
        var sendData={
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("AuthTest_Get1",sendData);
    },

    async api_AuthTest_Get2 ()
    {
        var sendData={
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("AuthTest_Get2",sendData);
    },

    async api_AuthTest_Get3 ()
    {
        var sendData={
        };
        return this.getApiManager_General().api_CallMethod_ByParamObj("AuthTest_Get3",sendData);
    },

    async api_AuthTest_Get4 ()
    {
        var sendData={
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("AuthTest_Get4",sendData);
    },

    async api_Cart_ClientEnsure ( )
    {
        var sendData={
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("Cart_ClientEnsure",sendData);
    },

    async api_Cart_ClientAddCartItem ( productSystemId,otherOption)
    {
        var method='Cart_ClientAddCartItem?productSystemId=' + productSystemId;
        var sendData={
            productSystemId:productSystemId,
            otherOption:JSON.stringify(otherOption),
        };

        return this.getApiManager_General().api_CallMethod_SendObj(method,sendData);
    },

    async api_Cart_ClientRemoveCartItem ( carItemSystemId)
    {
        var method='Cart_ClientRemoveCartItem?carItemSystemId=' + carItemSystemId;
        var sendData={
            carItemSystemId:carItemSystemId,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj(method,sendData);
    },

    async api_Cart_ClientUpdateCart ( cartRecordClient)
    {
        return this.getApiManager_General().api_CallMethod_SendObj("Cart_ClientUpdateCart",cartRecordClient);
    },

    async api_PayNewebPaySimple ( dAmount,sDesc)
    {
        var sendData={
            dAmount:dAmount,
            sDesc:sDesc,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("PayNewebPaySimple",sendData);
    },

    async api_PayEcPaySimple ( dAmount,sDesc)
    {
        var sendData={
            dAmount:dAmount,
            sDesc:sDesc,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("PayEcPaySimple",sendData);
    },

    async api_PayNewebPayMyCart (InfoOrderInput)
    {
        return this.getApiManager_General().api_CallMethod_ByParamObj("PayNewebPayMyCart",InfoOrderInput);
    },
    async api_PayEcPayMyCart (InfoOrderInput)
    {
        return this.getApiManager_General().api_CallMethod_ByParamObj("PayEcPayMyCart",InfoOrderInput);
    },

    async api_PayNewebPayForm (formSystemId)
    {
        var sendData={
            formSystemId:formSystemId,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("PayNewebPayForm",sendData);
    },

    async api_PayEcPayForm (formSystemId)
    {
        var sendData={
            formSystemId:formSystemId,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("PayEcPayForm",sendData);
    },

    async api_SendConfirmCode ( sClientKey, sTel)
    {
        var sendData={
            sClientKey:sClientKey,
            sTel:sTel,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("SendConfirmCode",sendData);
    },

    async api_MemberReg_Check_LoginId ( sLoginId)
    {
        var sendData={
            sLoginId:sLoginId,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("MemberReg_Check_LoginId",sendData);
    },

    async api_MemberReg_Check_ConfirmCode ( sClientKey, sConfirmCode)
    {
        var sendData={
            sClientKey:sClientKey,
            sConfirmCode:sConfirmCode,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("MemberReg_Check_ConfirmCode",sendData);
    },

    async api_MemberReg ( memberRecordWebInput)
    {
        return this.getApiManager_General().api_CallMethod_SendObj("MemberReg",memberRecordWebInput);
    },

    async api_MemeberLogin ( sLoginId, sPwd)
    {
        var sendData={
            sLoginId:sLoginId,
            sPwd:sPwd,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("MemeberLogin",sendData);
    },

    async api_Member_ResetPwd (sLoginId )
    {
        var sendData={
            sLoginId:sLoginId
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("Member_ResetPwd",sendData);
    },

    async api_Member_ChangePwd ( sCurrentPwd, sNewPwd)
    {
        var sendData={
            sCurrentPwd:sCurrentPwd,
            sNewPwd:sNewPwd,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("Member_ChangePwd",sendData);
    },

    async api_MemberBasicInfoGet ()
    {
        var sendData={
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("MemberBasicInfoGet",sendData);
    },

    async api_MemberBasicInfoSave ( memberRecordBasicInfo)
    {
        return this.getApiManager_General().api_CallMethod_SendObj("MemberBasicInfoSave",memberRecordBasicInfo);
    },

    //---------------------------------------
    async api_SiteSettingClient_Get ()
    {
        var sendData={
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("SiteSettingClient_Get",sendData);
    },

    async api_Order_ListQuery ( queryObject)
    {
        return this.getApiManager_General().api_CallMethod_SendObj("Order_ListQuery",queryObject);
    },
    async api_GetOrderQrCode(orderSystemId,orderDetailSystemId) {
        var sendData={
            OrderSystemId:orderSystemId,
            OrderDetailSystemId:orderDetailSystemId,
        };

        return this.getApiManager_General().api_CallMethod_SendObj("GetOrderQrCode",sendData);
    },

}
