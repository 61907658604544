import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import md5 from 'js-md5';

import jQuery from 'jquery'
import vuetify from '../../plugins/vuetify';
import DatetimePicker from 'vuetify-datetime-picker'
import * as dec  from "@/pages/common/declare";

import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'




// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import $ from 'jquery' //main.js導入(全域)
import SimpleVueValidation from 'simple-vue-validator';
import CKEditor from 'ckeditor4-vue';
// import Vuetify from 'vuetify'
// import 'vuetify/dist/vuetify.min.css'

import VueLodash from 'vue-lodash'
import lodash from 'lodash'

window.jQuery = jQuery
window.$ = jQuery



Vue.config.productionTip = false

// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)
Vue.prototype.$md5 = md5;
Vue.prototype.$dec=dec

import * as ubossDeclare from "@/pages/commonUBoss/ubossDeclare";
Vue.prototype.$u=ubossDeclare;

Vue.use(SimpleVueValidation);

// Vue.use(Vuetify)

Vue.use(CKEditor);
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.use(DatetimePicker)

//vue-loading-overlay
// Vue.use(VueLoading); //Use default options
Vue.use(VueLoading, {
  canCancel: true,
  color: '#000000',
  loader: 'dots', //spinner/dots/bars
  width: 50,
  height: 50,
  backgroundColor: '#ffffff',
  isFullPage: true,
  opacity: 0.8
});

//---------------------------------------
import { ValidationObserver,ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { localize } from 'vee-validate';
import zh_TW from 'vee-validate/dist/locale/zh_TW.json';


Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize('zh_TW',zh_TW);

localize({
  zh_TW: {
    messages: {
      required: '必填', //'「{_field_}」為必填'
      email:'須為有效的電子信箱',
      min: 'this field must have no less than {length} characters',
      max: (_, { length }) => `this field must have no more than ${length} characters`
    }
  }
});

//---------------------------------------
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

//---------------------------------------

import vSelect from 'vue-select'


Vue.component('vue-select', vSelect)



//---------------------------------------
// import Cleave from 'vue-cleave-component';
// Vue.use(Cleave)
// Vue.component('Cleave', Cleave)
//---------------------------------------
import VueNumeric from 'vue-numeric'

Vue.use(VueNumeric)
//---------------------------------------

Vue.filter('fmtDate', function (value) {
  if (!value) return ''

  return value.substr(0,19).replaceAll("T"," ")
})
Vue.filter('fmtTitle', function (value) {
  if (!value) return ''

  if (value.length<25) return value

  return value.substr(0,22) +"..."
})

Vue.directive('uppercase', {
  update (el) {
    el.value = el.value.toUpperCase()
  },
})

Vue.directive('numericOrEmpty', {
  bind(el) {
    el.addEventListener('keyup', () => {
      let regex = /^[0-9]*$/
      if (!regex.test(el.value)) {
        let regex2 = /\s/
        if (!regex2.test(el.value)) {
          el.value = el.value.slice(0, -1)
        }
      }
    })
  }
})

Vue.directive('numericOnly', {
  bind(el) {
    el.addEventListener('keyup', () => {
      let regex = /^[0-9]*$/
      if (!regex.test(el.value)) {
        el.value = el.value.slice(0, -1)
      }
    })
  }
})



// Vue.prototype.COMM_Update_OrderKey = function(id,tablename,orderkey){
//   apiAdmin.COMM_Update_OrderKey(id,tablename,orderkey)
// }
//
//
// Vue.prototype.COMM_Update_Status = function(id,tablename,status){
//   apiAdmin.COMM_Update_Status(id,tablename,status)
// }

import MyVuePlugin from "@/pages/common/plugin/MyVuePlugin";
Vue.use(MyVuePlugin)


new Vue({
  data: function() {
    return {
      data:"aa"
    };
  },
  computed: {
    bodyClass() {
      return this.$route.name;
    }
  },
  router,
  vuetify,
  store,
  render: h => h(App),
  created: function() {
  },
  mounted () {
      if (this.$route.name==="Index"){
        document.body.classList.add('path-front')
      }else{
        document.body.classList.add('path-content')
      }

  },
}).$mount('#wrapper')


// app.use(Cleave)