<template>
  <div>
    <iframe :src="dataUBossDocHomeUrl">

    </iframe>

  </div>
</template>

<script>
import {UBossDocHomeUrl} from "@/pages/commonUBoss/ubossDeclare";
import ubossHostManager from "@/pages/commonUBoss/ubossHostManager";


export default {
  data: function() {
    return {
      formData:{
        name:"Sophie Wang",
      },
      dataUBossDocHomeUrl:""
    };
  },
  methods: {
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {
    let ubossHost=new ubossHostManager();
    let url=ubossHost.admUrl();

    this.dataUBossDocHomeUrl=url+UBossDocHomeUrl;
    //this.loadData()
  },
}
</script>


<style scoped>
iframe {
  display: block;       /* iframes are inline by default */
  background: #000;
  border: none;         /* Reset default border */
  height: 100vh;        /* Viewport-relative units */
  width: 85vw;
}
</style>