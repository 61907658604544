import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Login from '../views/Login.vue';
import Test from '../views/Test.vue';
import ubossCommon from "@/pages/commonUBoss/ubossCommon";

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/Test',
    name: 'Test',
    component: Test
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!ubossCommon.IsLoginAdm()) {
      next({ name: 'Login' })
    } else {
      next() // go to wherever I'm going
    }

  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

//
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (session.isLogined()) {
//       // if (!to.meta.roles) {
//       //   return next()
//       // }
//       // if (to.meta.roles.includes(store.state.user.rol)) {
//       //   switch (store.state.user.rol) {
//       //     case 'USER':
//       //       next({
//       //         name: 'UserHome'
//       //       })
//       //       break;
//       //     case 'ADMIN':
//       //       next({
//       //         name: 'AccountsHome'
//       //       })
//       //       break;
//       //     default:
//       //       next({
//       //         name: 'UserHome'
//       //       })
//       //   }
//       // }
//     } else {
//       next()
//     }
//
//     // this route requires auth, check if logged in
//     // if true, redirect to main page.
//     // if (store.getters.isUserLoggedIn) {
//     //   if (!to.meta.roles) {
//     //     return next()
//     //   }
//     //   if (to.meta.roles.includes(store.state.user.rol)) {
//     //     switch (store.state.user.rol) {
//     //       case 'USER':
//     //         next({
//     //           name: 'UserHome'
//     //         })
//     //         break;
//     //       case 'ADMIN':
//     //         next({
//     //           name: 'AccountsHome'
//     //         })
//     //         break;
//     //       default:
//     //         next({
//     //           name: 'UserHome'
//     //         })
//     //     }
//     //   }
//     // } else {
//     //   next()
//     // }
//   }
// })