<template>
  <div id="wrapper">
    <div v-if="showMenu" >
      <COM_Menu></COM_Menu>
    </div>


  <!-- Content Wrapper -->
  <div id="content-wrapper" class="d-flex flex-column">

    <!-- Main Content -->
    <div id="content">
      <COM_Header></COM_Header>

      <router-view/>
    </div>
    <COM_Footer></COM_Footer>
  </div>
  <!-- End of Content Wrapper -->
  </div>

</template>

<style>

[v-cloak]{
  display: none;
}


.mySearchPanel{
  max-width: 1600px;
}

.mySearchPanel_toolbar{
  max-width: 1600px;
}
.myFormContainer{
  max-width: 1024px;
}

.mySearchPanel{
  max-width: 1600px;
}

.mySearchPanel_txtKeyword {
  width: 300px !important;
  display: inline !important;
}

.myGridPanel{
  max-width: 1600px;
}

.mybutton{
  color: white !important;
  margin: 10px;
}

.errorAdm{
  border-color:red;
}

.message{
  border-color:red;
  color: red;
}
.require{
  color: red;
}

.myInput_Date{
  max-width: 185px;
  padding: 0px;
    display: block !important;
}
.myInput_DateTime{
  max-width: 230px;
  padding: 0px;
    display: block !important;
}

.sidebar{
  width: 18em !important;
}

select{
  appearance: menulist !important;
}

</style>


<script>
import COM_Header from "./views/components/COM_Header";
import COM_Footer from "./views/components/COM_Footer";
import COM_Menu from "./views/components/COM_Menu";

import '@progress/kendo-theme-default/dist/all.css';

export default {
  name: '',
  components: {COM_Menu, COM_Header,COM_Footer},
  // components: {
  //   HelloWorld
  // }
  data: function() {
    return {
      showMenu:true,
      // layout1: true,
      // layout2: false,
    };
  },
  methods: {
  },
  created() {
    if (this.$route.name==="Login"){
      this.showMenu=false
    }

  },
  mounted () {
    // if (this.$route.name==="MyWorkspace"){
    //   this.layout1=false;
    //   this.layout2=true;
    // }else{
    //   this.layout1=true;
    //   this.layout2=false;
    // }


  },
}
</script>
